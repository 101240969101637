import React, { useEffect, useState } from 'react';
import Header from './components/header/Header';
import HeroBody from './components/hero-section/HeroBody';
import Footer from './components/footer/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Blog from './components/blog/Blog';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import SideBar from './components/hero-section/SideBar';
import RecomendedHome from './components/recommended/RecomendedHome';
import CategoryCard from './components/category/CategoryCard';
import { constant } from './utils/constant';

const App = () => {
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [allCategory, setAllCategory] = useState([]);
  const handleSideBar = () => {
    setToggleSideBar(!toggleSideBar);
  };

  const fetchAllCategory = async () => {
    try {
      const response = await fetch(constant.CONST_SARCASM_BLOG_CATEGORY);
      const result = await response.json();
      if (result.success) {
        setAllCategory(result.data);
      }
    } catch (error) {
      console.error('Error fetching all categories:', error);
    }
  };

  useEffect(() => {
    fetchAllCategory();
  }, []);
  return (
    <div>
      <Router>
        <Header handleSideBar={handleSideBar} />
        <SideBar toggleSideBar={toggleSideBar} handleSideBar={handleSideBar} />
        <Routes>
          <Route
            path="/"
            exact
            element={<HeroBody allCategory={allCategory} />}
          />
          <Route path="/about" exact element={<About />} />
          <Route path="/blog/:id" exact element={<Blog  allCategory={allCategory}/>} />
          <Route path="/categories/:id" exact element={<CategoryCard />} />
          <Route path="/recommended-blogs" exact element={<RecomendedHome />} />
          <Route path="/contact" exact element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
