import React from "react";
import aboutImg1 from "../../assets/about/about1.webp";
import aboutImg2 from "../../assets/about/about2.webp";
import aboutImg3 from "../../assets/about/about3.webp";
import sarcasm from "../../assets/pages/sarcasm.jpg";
import sadcasm from "../../assets/pages/sadcasm.jpg";
import scrawledStories from "../../assets/pages/scrawled-stories.jpg";
import bookofTeenager from "../../assets/pages/book-of-teenager.jpg";
import trollFuckers from "../../assets/pages/troll-fuckers.jpg";
import xavierUnclecool from "../../assets/pages/zavier-uncle-cool.jpg";
import bharityaCorporate from "../../assets/pages/bhartiya-corporate.png";
import bollywoodCop from "../../assets/pages/bollywood-cop.png";
import scrollingCulture from "../../assets/pages/scrolling-culture.png";
import indiapopCulture from "../../assets/pages/india-pop-culture.png";
import memeBoy from "../../assets/pages/indian-meme-boy.png";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const About = () => {
  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div className="slick_next slick-arrow" onClick={onClick}>
        <i className="bi bi-arrow-right"></i>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="slick_prev slick-arrow" onClick={onClick}>
        <i className="bi bi-arrow-left"></i>
      </div>
    );
  }

  const carouselSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 1,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <section className="about-wrap-layout-2 space-top-60 space-bottom-60 bg-color-light-1">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="content-holder text-center">
                <h1>Who We Are</h1>
                <p>
                  Sarcasm transforms humor into influence and memes into
                  marketing gold. We’re the masterminds behind 70 million
                  Facebook smiles and 12 million Instagram chuckles. Now, we’re
                  taking that power to help brands master the fine art of being
                  unforgettable online. Because memes aren’t just funny—they’re
                  effective.
                </p>
                <p>
                  At Sarcasm, we don’t just run the world’s largest meme
                  page—we’ve built an empire. With 50+ Instagram IPs boasting a
                  combined 300M followers and partnerships with 10,000 IPs
                  reaching over 1 billion fans, we’re like the Avengers of
                  social media (but funnier). Whether it’s memes, marketing, or
                  niche seeding, we’ve got the network to make your brand go
                  viral faster than a cat video.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-wrap-layout-2 space-top-60 space-bottom-60 bg-color-selago">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10 col-12">
              <div className="content-holder text-center">
                <h1>What We Do</h1>
                <p>
                  What started as a meme marketing powerhouse has evolved into a
                  full-fledged marketing agency. At Sarcasm, we combine
                  creativity and strategy to deliver impactful campaigns across
                  platforms.
                </p>
                <p>Our services include:</p>
              </div>
            </div>
          </div>
          <div className="row aboutSrvcList">
            <div className="col-12">
              <ul>
                <li>
                  <span>
                    <i class="bi bi-asterisk"></i>
                  </span>
                  A-New-Age PR
                </li>
                <li>
                  <span>
                    <i class="bi bi-asterisk"></i>
                  </span>
                  ORM
                </li>
                <li>
                  <span>
                    <i class="bi bi-asterisk"></i>
                  </span>
                  Instagram Seeding
                </li>
                <li>
                  <span>
                    <i class="bi bi-asterisk"></i>
                  </span>
                  Facebook Seeding
                </li>
                <li>
                  <span>
                    <i class="bi bi-asterisk"></i>
                  </span>
                  X Trend & Seeding
                </li>
                <li>
                  <span>
                    <i class="bi bi-asterisk"></i>
                  </span>
                  Threads
                </li>
                <li>
                  <span>
                    <i class="bi bi-asterisk"></i>
                  </span>
                  UGC
                </li>
                <li>
                  <span>
                    <i class="bi bi-asterisk"></i>
                  </span>
                  Socials
                </li>
                <li>
                  <span>
                    <i class="bi bi-asterisk"></i>
                  </span>
                  DVC
                </li>
                <li>
                  <span>
                    <i class="bi bi-asterisk"></i>
                  </span>
                  Stunt Ideas
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="about-wrap-layout-2 space-top-60 space-bottom-60 bg-color-light-1">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="content-holder text-center">
                <h1>SARCASM</h1>
                <h3>
                  Life’s too short for boring marketing. Let’s make it
                  memorable.
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="aboutPageSlider">
          <div className="container-fluid">
            <Slider {...carouselSettings}>
              <a href="https://www.instagram.com/sarcastic_us/" target="_blank">
                <div className="post-box-layout2 box-border-dark-1 radius-default bg-color-old-lace box-shadow-large shadow-style-1 transition-default">
                  <div className="aboutPageSlideBox">
                    <div className="aboutPageSlideImg">
                      <img src={sarcasm} alt="Sarcastic_us" />
                    </div>
                    <h4>Sarcastic_us</h4>
                  </div>
                </div>
              </a>
              <a href="https://www.facebook.com/Sadcasm/" target="_blank">
                <div className="post-box-layout2 box-border-dark-1 radius-default bg-color-old-lace box-shadow-large shadow-style-1 transition-default">
                  <div className="aboutPageSlideBox">
                    <div className="aboutPageSlideImg">
                      <img src={sadcasm} alt="Sadcasm Facebook" />
                    </div>
                    <h4>Sadcasm Facebook </h4>
                  </div>
                </div>
              </a>
              <a href="https://www.facebook.com/SarcasmLol/" target="_blank">
                <div className="post-box-layout2 box-border-dark-1 radius-default bg-color-old-lace box-shadow-large shadow-style-1 transition-default">
                  <div className="aboutPageSlideBox">
                    <div className="aboutPageSlideImg">
                      <img src={sarcasm} alt="Sarcasm Facebook" />
                    </div>
                    <h4>Sarcasm Facebook</h4>
                  </div>
                </div>
              </a>
              <a
                href="https://www.instagram.com/scrawledstories/"
                target="_blank"
              >
                <div className="post-box-layout2 box-border-dark-1 radius-default bg-color-old-lace box-shadow-large shadow-style-1 transition-default">
                  <div className="aboutPageSlideBox">
                    <div className="aboutPageSlideImg">
                      <img src={scrawledStories} alt="Scrawled Stories" />
                    </div>
                    <h4>Scrawled Stories</h4>
                  </div>
                </div>
              </a>
              <a
                href="https://www.instagram.com/bookofteenager/"
                target="_blank"
              >
                <div className="post-box-layout2 box-border-dark-1 radius-default bg-color-old-lace box-shadow-large shadow-style-1 transition-default">
                  <div className="aboutPageSlideBox">
                    <div className="aboutPageSlideImg">
                      <img src={bookofTeenager} alt="Bookofteenager" />
                    </div>
                    <h4>Book of Teenager</h4>
                  </div>
                </div>
              </a>
              <a href="https://www.instagram.com/trollfuckers/" target="_blank">
                <div className="post-box-layout2 box-border-dark-1 radius-default bg-color-old-lace box-shadow-large shadow-style-1 transition-default">
                  <div className="aboutPageSlideBox">
                    <div className="aboutPageSlideImg">
                      <img src={trollFuckers} alt="Trollfuckers " />
                    </div>
                    <h4>Trollfuckers </h4>
                  </div>
                </div>
              </a>
              <a
                href="https://www.instagram.com/xavierunclecoool/"
                target="_blank"
              >
                <div className="post-box-layout2 box-border-dark-1 radius-default bg-color-old-lace box-shadow-large shadow-style-1 transition-default">
                  <div className="aboutPageSlideBox">
                    <div className="aboutPageSlideImg">
                      <img src={xavierUnclecool} alt="Xavierunclecool " />
                    </div>
                    <h4>Xavier Uncle Cool</h4>
                  </div>
                </div>
              </a>
              <a
                href="https://www.instagram.com/bhartiyacorporate/"
                target="_blank"
              >
                <div className="post-box-layout2 box-border-dark-1 radius-default bg-color-old-lace box-shadow-large shadow-style-1 transition-default">
                  <div className="aboutPageSlideBox">
                    <div className="aboutPageSlideImg">
                      <img src={bharityaCorporate} alt="Bharityacorporate " />
                    </div>
                    <h4>Bharitya Corporate </h4>
                  </div>
                </div>
              </a>
              <a href="https://www.instagram.com/bollywoodcop/" target="_blank">
                <div className="post-box-layout2 box-border-dark-1 radius-default bg-color-old-lace box-shadow-large shadow-style-1 transition-default">
                  <div className="aboutPageSlideBox">
                    <div className="aboutPageSlideImg">
                      <img src={bollywoodCop} alt="Bollywoodcop " />
                    </div>
                    <h4>Bollywood Cop</h4>
                  </div>
                </div>
              </a>
              <a
                href="https://www.instagram.com/scrollingculture/"
                target="_blank"
              >
                <div className="post-box-layout2 box-border-dark-1 radius-default bg-color-old-lace box-shadow-large shadow-style-1 transition-default">
                  <div className="aboutPageSlideBox">
                    <div className="aboutPageSlideImg">
                      <img src={scrollingCulture} alt="Scrollingculture " />
                    </div>
                    <h4>Scrolling Culture</h4>
                  </div>
                </div>
              </a>
              <a
                href="https://www.instagram.com/indiapopculture/"
                target="_blank"
              >
                <div className="post-box-layout2 box-border-dark-1 radius-default bg-color-old-lace box-shadow-large shadow-style-1 transition-default">
                  <div className="aboutPageSlideBox">
                    <div className="aboutPageSlideImg">
                      <img src={indiapopCulture} alt="Indiapopculture " />
                    </div>
                    <h4>Indiapopculture</h4>
                  </div>
                </div>
              </a>
              <a
                href="https://www.instagram.com/indian.memeboy/"
                target="_blank"
              >
                <div className="post-box-layout2 box-border-dark-1 radius-default bg-color-old-lace box-shadow-large shadow-style-1 transition-default">
                  <div className="aboutPageSlideBox">
                    <div className="aboutPageSlideImg">
                      <img src={memeBoy} alt="Indian Memeboy" />
                    </div>
                    <h4>Indian Memeboy</h4>
                  </div>
                </div>
              </a>
            </Slider>
          </div>
        </div>
      </section>

      <section className="about-wrap-layout-2 space-top-30 space-bottom-30 bg-color-light-1">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="content-holder text-center">
                <h1>Sarcasm Group by Numbers</h1>
              </div>
            </div>
          </div>
          <div className="row aboutNumber">
            <div class="col-md-4 col-12">
              <div class="aboutNumberBox">
                <h1>
                  20<small>B</small>
                </h1>
                <h4>Views per month from all the page</h4>
              </div>
            </div>
            <div class="col-md-4 col-12">
              <div class="aboutNumberBox">
                <h1>
                  200<small>M</small>
                </h1>
                <h4>Total followers, all platforms combined</h4>
              </div>
            </div>
            <div class="col-md-4 col-12">
              <div class="aboutNumberBox">
                <h1>
                  10<small>K</small>
                </h1>
                <h4>Partner Profiles</h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Team Section */}
      {/* <section className="team-wrap-layout2 space-top-50 bg-color-light-1 transition-default">
        <div className="container">
          <div className="section-heading heading-style-10">
            <h2 className="title">Meet Our Team</h2>
            <p className="description">
              Most text editors like MS Word or Lotus Notes generate random
              lorem text when needed...
            </p>
          </div>
          <div className="row g-3">
            {teamMembers.map((member, index) => (
              <div className="col-md-4" key={index}>
                <div className="team-layout-2">
                  <div className="figure-holder">
                    <a href="author.html" className="link-wrap img-height-100">
                      <img
                        width="520"
                        height="520"
                        src={member.image}
                        alt="Team"
                      />
                    </a>
                  </div>
                  <div className="content-holder">
                    <div>
                      <h3 className="entry-title h3-medium">
                        <a href="author.html" className="link-wrap">
                          {member.name}
                        </a>
                      </h3>
                      <div className="entry-designation text-b4 f-w-500">
                        {member.designation}
                      </div>
                    </div>
                    <div className="axil-social social-layout-1 size-medium gap-8">
                      <ul>
                        {member.social.map((social, i) => (
                          <li key={i} className={social.platform}>
                            <a
                              aria-label={`Learn more from ${social.platform}`}
                              href={social.link}
                            >
                              <i className={`bi bi-${social.platform}`}></i>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}
    </div>
  );
};

const teamMembers = [
  {
    name: "Georges Embolo",
    designation: "Publisher",
    image: "../../assets/team/team4.webp",
    social: [
      { platform: "pinterest", link: "#" },
      { platform: "twitter", link: "#" },
      { platform: "mail-fast", link: "#" },
    ],
  },
  {
    name: "Nayah Tantoh",
    designation: "Publisher",
    image: "../../assets/team/team5.webp",
    social: [
      { platform: "pinterest", link: "#" },
      { platform: "twitter", link: "#" },
      { platform: "mail-fast", link: "#" },
    ],
  },
  {
    name: "John Philipe",
    designation: "Publisher",
    image: "a../../assets/team/team6.webp",
    social: [
      { platform: "pinterest", link: "#" },
      { platform: "twitter", link: "#" },
      { platform: "mail-fast", link: "#" },
    ],
  },
];

export default About;
