import React, { useEffect, useState } from 'react';
import HeroArea from './HeroArea';
import HeroBanner from './HeroBanner';
import PostAreaCarousel from './PostAreaCarousel';
import TopStories from './TopStories';
import CategoryArea from './CategoryArea';
// import TopVideos from './TopVideos';
// import RecentArticles from './RecentArticles';
import NewRecentArticles from './NewRecentArticles';
import TopColumnist from './TopColumnist';
import CardContainer from './CardContainer';
import NewsLetterEmail from './NewsLetterEmail';
import { constant } from '../../utils/constant';
import { Skeleton } from '@mui/material';
import CategoryHighlightSection from './CategoryHighlightSection';

const HeroBody = ({ allCategory }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newsBlogs, setNewsBlogs] = useState([]);
  const [entertainmentBlogs, setEntertainmentBlogs] = useState([]);
  const [sportsBlogs, setSportsBlogs] = useState([]);
  const [marketingBlogs, setMarketingBlogs] = useState([]);

  const categories = ['Entertainment', 'News', 'Marketing', 'Sports'];
  const [allCategoryBlogs, setAllCategoryBlogs] = useState([]);

  const fetchData = async () => {
    try {
      const response = await fetch(constant.CONST_SARCASM_BLOG_RECENT);
      const json = await response.json();
      if (json.success) {
        setData(json.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  function shuffleArray(array) {
    return array.sort(() => Math.random() - 0.5);
  }

  useEffect(() => {
    if (data.length > 0) {
      const categoriesBloggs = [];
      let entertainmentData = [];
      let newsData = [];
      let marketingData = [];
      let sportstData = [];

      data?.forEach((item) => {
        if (item.categoryName && categories.some((category) => item.categoryName.includes(category))) {
          // Add the latest blog if the limit is not reached
          if (categoriesBloggs.length < 4 && item.recentBlogs?.[0]) {
            categoriesBloggs.push(item.recentBlogs[0]);
          }

          // if (categoriesBloggs.length >= 4) {
          //   const remainingBlogs = shuffleArray(item.recentBlogs).slice(0, 2);
          //   remainingBlogs.forEach((blog) => {
          //     // if (categoriesBloggs.length < 6) {
          //     categoriesBloggs.push(blog);
          //     // }
          //   });
          // }
        }
        switch (item.categoryName) {
          case 'Entertainment':
            entertainmentData.push(item);
            break;
          case 'News':
            newsData.push(item);
            break;
          case 'Marketing':
            marketingData.push(item);
            break;
          case 'Sports':
            sportstData.push(item);
            break;
          default:
            break;
        }
      });

      // Set the state values
      setAllCategoryBlogs(categoriesBloggs);
      setEntertainmentBlogs(entertainmentData);
      setNewsBlogs(newsData);
      setMarketingBlogs(marketingData);
      setSportsBlogs(sportstData);
    }
  }, [data]);

  const marketingData = marketingBlogs.length ? marketingBlogs[0]?.recentBlogs : [];
  const newsData = newsBlogs.length ? newsBlogs[0]?.recentBlogs : [];
  const sportsData = sportsBlogs.length ? sportsBlogs[0]?.recentBlogs : [];
  const entertainmentData = entertainmentBlogs.length ? entertainmentBlogs[0]?.recentBlogs : [];

  const reversedCategory = [...allCategory].reverse();
  const revesedBlog = [...allCategoryBlogs].reverse()

  return (
    <div>
      {/* <HeroArea /> */}
      {loading ? <Skeleton variant="rectangular" height={120} width="65%" style={{ margin: '0 auto 20px' }} /> : <HeroBanner data={allCategoryBlogs} allCategory={allCategory} />}
      {/* {loading ? <Skeleton variant="rectangular" height={120} width="65%" style={{ margin: '0 auto 20px' }} /> : <PostAreaCarousel data={allCategoryBlogs} allCategory={allCategory} />} */}
      {/* {loading ? <Skeleton variant="rectangular" height={120} width="65%" style={{ margin: '0 auto 20px' }} /> : <CategoryHighlightSection data={data} />} */}
      {/* {loading ? <Skeleton variant="rectangular" height={120} width="65%" style={{ margin: '0 auto 20px' }} /> : <CategoryArea data={data} />} */}
      {loading ? <Skeleton variant="rectangular" height={120} width="65%" style={{ margin: '0 auto 20px' }} /> : <TopStories data={revesedBlog} allCategory={reversedCategory} />}
      {loading ? <Skeleton variant="rectangular" height={120} width="65%" style={{ margin: '0 auto 20px' }} /> : <CategoryHighlightSection data={newsData} allCategory={allCategory} title="News" />}
      {loading ? <Skeleton variant="rectangular" height={120} width="65%" style={{ margin: '0 auto 20px' }} /> : <CategoryHighlightSection data={sportsData} allCategory={allCategory} title="Sports" />}
      {loading ? <Skeleton variant="rectangular" height={120} width="65%" style={{ margin: '0 auto 20px' }} /> : <CategoryHighlightSection data={entertainmentData} allCategory={allCategory} title="Entertainment" />}
      {loading ? <Skeleton variant="rectangular" height={120} width="65%" style={{ margin: '0 auto 20px' }} /> : <CategoryHighlightSection data={marketingData} allCategory={allCategory} title="Marketing" />}

      {/* {loading ? <Skeleton variant="rectangular" height={120} width="65%" style={{ margin: '0 auto 20px' }} /> : <TopVideos />} */}
      {/* {loading ? <Skeleton variant="rectangular" height={120} width="65%" style={{ margin: '0 auto 20px' }} /> : <RecentArticles data={data} />}
      {loading ? <Skeleton variant="rectangular" height={120} width="65%" style={{ margin: '0 auto 20px' }} /> : <NewRecentArticles data={data} />} */}
      {/* <TopColumnist /> */}
      {/* {loading ? <Skeleton variant="rectangular" height={120} width="65%" style={{ margin: '0 auto 20px' }} /> : <CardContainer data={data} />} */}
      {/* <NewsLetterEmail /> */}
    </div>
  );
};

export default HeroBody;
