import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const HeroBanner = ({ data, allCategory }) => {
  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div className="slick_next slick-arrow" onClick={onClick}>
        <i className="bi bi-arrow-right"></i>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="slick_prev slick-arrow" onClick={onClick}>
        <i className="bi bi-arrow-left"></i>
      </div>
    );
  }

  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const mostRecent = data?.reduce((prev, current) => {
    const prevDate = prev?.createdAt ? new Date(prev?.createdAt) : new Date(0);
    const currentDate = current?.createdAt
      ? new Date(current.createdAt)
      : new Date(0);
    return currentDate > prevDate ? current : prev;
  }, {});

  // const divStyle = {
  //   backgroundImage: `url(${mostRecent?.bannerImageUrl})`,
  //   backgroundSize: 'cover',
  //   backgroundPosition: 'center',
  // };

  const getCategoryName = (categoryId) => {
    const category = allCategory.find((cat) => cat._id === categoryId);
    return category ? category.name : "Unknown Category";
  };
  console.log("catname");

  return (
    <section className="post-wrap-layout1 bg-color-light-1 transition-default heroBanner">
      <div className="container">
        <div className="row g-3">
          <div className="col-lg-7 heroBannerLeft">
            <div className="post-box-layout1 box-border-dark-1 radius-default transition-default overflow-hidden">
              {mostRecent?.bannerImageUrl && (
                <a className="link-wrap figure-overlay img-height-100 h-100">
                  <img
                    id="videoPlayer-1"
                    className="image-mask videoPlayer-1  radius-medium"
                    src={mostRecent?.bannerImageUrl}
                    alt="banner-img"
                  />
                </a>
              )}
              <div className="content-holder">
                <h3 className="entry-title h3-large color-light-1-fixed underline-animation mb-0">
                  <Link to={`/blog/${mostRecent?._id}`}>
                    {mostRecent?.title}
                  </Link>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-lg-5 heroBannerRight">
            <Slider {...carouselSettings}>
              {data?.map((item) =>
                item?.bannerImageUrl !== null ? (
                  <div key={item._id} className="h-100">
                    <div
                      id="post-slider-1"
                      className="post-slider-1 gutter-6 initially-none h-100"
                    >
                      <div className="h-100">
                        <div className="h-100 post-box-layout2 box-border-dark-1 radius-default padding-30 bg-color-old-lace box-shadow-large shadow-style-1 transition-default">
                          <div className="figure-holder radius-default">
                            <Link
                              to={`/blog/${item?._id}`}
                              className="link-wrap"
                            >
                              <img
                                className="object-fit-cover"
                                src={item?.bannerImageUrl}
                                alt="Post"
                              />
                            </Link>
                          </div>
                          <div className="content-holder">
                            <div className="entry-category style-1 color-dark-1-fixed">
                              <ul>
                                <li>
                                  <Link to={`/blog/${item?._id}`}>
                                    {getCategoryName(item?.blogCategoryId)}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <h3 className="entry-title color-dark-1-fixed underline-animation">
                              <Link
                                to={`/blog/${item?._id}`}
                                className="link-wrap"
                              >
                                {item?.title}
                              </Link>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null
              )}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroBanner;
